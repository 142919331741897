<template>
  <b-table
    scrollable
    class="table is-fullwidth auction-table-listing"
    detail-key="id"
    ref="auction-table"
    :data="auctions"
    :detailed="displayColumn('Itens')"
    :opened-detailed="
      auctions.filter(auction => auction.itens.some(item => item.id)).map(auction => auction.id)
    "
    :show-detail-icon="false"
  >
    <b-table-column
      centered
      field="comprador.nome"
      label="Comprador"
      v-slot="props"
      :sortable="isNotBuyer"
      :visible="displayColumn('Comprador')"
    >
      <div class="auction-column">
        {{ props.row.comprador.nome }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="numero_processo"
      label="Processo"
      v-slot="props"
      :visible="displayColumn('Processo')"
    >
      <div class="auction-column">
        {{ props.row.numero_processo || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="numero_oc"
      label="Número"
      v-slot="props"
      :visible="displayColumn('Número')"
    >
      <div class="auction-column">
        <router-link
          v-if="props.row.situacao.id > 1"
          :to="{ name: 'Pregao', params: { id: props.row.id } }"
        >
          {{ props.row.numero_oc }}
        </router-link>
        <p v-else>{{ props.row.numero_oc || 'N/A' }}</p>
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_pregao"
      label="Tipo"
      v-slot="props"
      :visible="displayColumn('Tipo')"
    >
      <div class="auction-column">
        {{ formatAuctionType(props.row.tipo_pregao) }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="modo_disputa"
      label="Disputa"
      v-slot="props"
      :visible="displayColumn('Disputa')"
    >
      <div class="auction-column">
        {{ formatDisputeMode(props.row.modo_disputa) }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_negociacao"
      label="Negociação"
      v-slot="props"
      :visible="displayColumn('Negociação')"
    >
      <div class="auction-column">
        {{ props.row.tipo_negociacao.nome }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_edital"
      label="Edital"
      v-slot="props"
      :visible="displayColumn('Edital')"
    >
      <div class="auction-column">
        {{ formatNotice(props.row.tipo_edital) }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_edital"
      label="Preferência Regional"
      v-slot="props"
      :visible="displayColumn('Preferência Regional')"
    >
      <div class="auction-column">
        {{ props.row.preferencia_regional ? 'Sim' : 'Não' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="objeto"
      label="Objeto"
      v-slot="props"
      :visible="displayColumn('Objeto')"
    >
      <div class="auction-column">
        {{ props.row.objeto || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="data_publicacao"
      label="Publicação"
      v-slot="props"
      :visible="displayColumn('Publicação')"
    >
      <div class="auction-column">
        {{ props.row.data_publicacao ? formatDate(props.row.data_publicacao) : 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="data_abertura"
      label="Abertura"
      v-slot="props"
      :visible="displayColumn('Abertura')"
    >
      <div class="auction-column">
        {{ props.row.data_abertura ? formatDate(props.row.data_abertura) : 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="data_reabertura"
      label="Reabertura"
      v-slot="props"
      :visible="displayColumn('Reabertura')"
    >
      <div class="auction-column">
        {{ props.row.data_reabertura ? formatDate(props.row.data_reabertura) : 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="origem_recursos"
      label="Origem dos Recursos"
      v-slot="props"
      :visible="displayColumn('Origem dos Recursos')"
    >
      <div class="auction-column">
        <div v-if="props.row.origem_recursos.length">
          <div v-for="(resourceOrigin, index) in props.row.origem_recursos" :key="index">
            <span>{{ resourceOrigin }}</span>
          </div>
        </div>

        <span v-else>{{ 'N/A' }}</span>
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="numero_instrumento"
      label="Nº Instrumento"
      v-slot="props"
      :visible="displayColumn('Nº Instrumento')"
    >
      <div class="auction-column">
        {{ props.row.numero_instrumento || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="ano_instrumento"
      label="Ano Instrumento"
      v-slot="props"
      :visible="displayColumn('Ano Instrumento')"
    >
      <div class="auction-column">
        {{ props.row.ano_instrumento || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="situacao.nome"
      label="Situação"
      v-slot="props"
      :visible="displayColumn('Situação')"
    >
      <div class="auction-column">
        {{ situationName(props.row) }}
      </div>
    </b-table-column>

    <template v-slot:detail="props">
      <div v-if="props.row.itens.some(element => element.id)" class="item-container">
        <div
          class="box item-content"
          v-for="item in props.row.itens"
          :key="item.identificador"
          :ref="'item-container'"
        >
          <div><strong>ID: </strong>{{ item.identificador }}</div>
          <div :class="{ 'elipsis-description': !expandedDescription }" :ref="'description'">
            <strong>Descrição: </strong>{{ item.descricao }}
          </div>
          <div v-if="item.valorUnitario">
            <strong>Valor Unitário: </strong>{{ item.valorUnitario | formatValue }}
          </div>
          <div v-if="item.valorTotal">
            <strong>Valor Total: </strong>{{ item.valorTotal | formatValue }}
          </div>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatarData as formatDate, formatarValor as formatValue } from '@/utils/format';

export default {
  name: 'AuctionTable',
  props: {
    columns: { type: Array, default: () => [] },
    expandedDescription: { type: Boolean, default: false },
  },
  data() {
    return {
      formatDate,
    };
  },
  filters: {
    formatValue,
  },
  computed: {
    ...mapGetters({
      auctions: 'obterOfertasCompra',
      function: 'getFunction',
    }),
    isNotBuyer() {
      return ['AUTORIDADE', 'PREGOEIRO', 'COMPRADOR'].indexOf(this.function) === -1;
    },
  },
  methods: {
    closedMode(row) {
      return this.auctions.some(
        ofertaCompra =>
          ofertaCompra.id === row.id &&
          ofertaCompra.itens.some(
            item => ['LANCE-FECHADO', 'PRIMEIRA-CHAMADA', 'NOVA-CHAMADA'].indexOf(item.estado) > -1,
          ),
      );
    },
    displayColumn(column) {
      return this.columns.some(element => element.name === column && element.display);
    },
    formatAuctionType(type) {
      if (!type) return 'N/A';

      return {
        IMEDIATO: 'Contratação Imediata',
        REGISTRO: 'Registro de Preços',
      }[type];
    },
    formatDisputeMode(mode) {
      if (!mode) return 'N/A';

      return {
        ABERTO: 'Aberto',
        FECHADO: 'Fechado',
      }[mode];
    },
    formatNotice(participation) {
      if (!participation) return 'N/A';

      return {
        AMPLO: 'Participação Ampla',
        AMPLOCOTA: 'Com Quota Reservada para ME/EPP/MEI/Cooperativa Preferência',
        RESERVADO: 'Participação Exclusiva de ME/EPP/MEI/Cooperativa Preferência',
      }[participation];
    },
    situationName(row) {
      const situation = row.situacao.nome;
      if (situation === 'Lances' && row.modo_disputa === 'FECHADO' && this.closedMode(row)) {
        return 'Lances - Fechado';
      }
      if (situation === 'Lances' && row.modo_disputa === 'FECHADO') return 'Lances - Aberto';
      if (situation === 'Habilitação' && row.prazo_adesao) {
        return 'Habilitação da Adesão';
      }
      if (situation === 'Fracassada') return 'Fracassado';
      if (situation === 'Deserta') return 'Deserto';
      if (situation === 'Revogada') return 'Revogado';
      if (situation === 'Encerrada com Vencedor') return 'Encerrado com Vencedor';
      if (situation === 'Encerrada com Recurso') return 'Encerrado com Recurso';
      if (situation === 'Suspensa') return 'Suspenso';
      return situation;
    },
  },
};
</script>

<style lang="scss" scoped>
.auction-table-listing {
  font-size: 0.88rem;
}

.elipsis-description {
  max-width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.item-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.item-content {
  background-color: #ededed;
  margin: 1rem 0.5rem;
  min-width: 300px;
  text-align: justify;
}

@media (max-width: 568px) {
  .elipsis-description {
    max-width: 33ch;
  }
}
</style>

<style>
.auction-table-listing > div > table > thead > tr {
  white-space: nowrap;
}

.auction-table-listing > div > table > tbody > tr > td > .detail-container {
  padding: unset !important;
}
</style>
