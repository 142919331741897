<template>
  <div class="auction-listing-container">
    <b-loading is-full-page :active="loading" can-cancel></b-loading>

    <AppTitle expand-title titulo="Listagem de Pregões Eletrônicos" />

    <section>
      <div class="container is-fluid auction-filters-container">
        <Auction-Filters />
      </div>
    </section>

    <section>
      <div class="container is-fluid auction-options-container">
        <Report-Options
          :columns.sync="columns"
          :display-expanded-description="displayExpandedDescription"
          @updateExpanded="value => (expandedDescription = value)"
        />
      </div>
    </section>

    <section>
      <div class="container is-fluid auction-table-container">
        <div v-if="!auctions.length" class="panel">
          <div class="panel-block empty-panel">
            <section v-if="!auctions.length" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhum Pregão Eletrônico.</p>
              </div>
            </section>
          </div>
        </div>

        <div v-if="auctions.length" class="panel">
          <div class="box panel-block">
            <Auction-Table
              ref="auction-table"
              :columns="columns"
              :expanded-description="expandedDescription"
            />
          </div>
        </div>
      </div>

      <div class="container is-fluid pagination-container">
        <AppPagination
          :data-filters="filters"
          :has-margin="false"
          :total="count"
          @change-page="
            page => {
              (filters.page = page), filtrarPregoes({ page });
            }
          "
          @change-per-page="
            perPage => {
              (filters.perPage = perPage), alterarPorPaginaPregoes(perPage);
            }
          "
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Printd } from 'printd';

import AppPagination from '@/components/AppPagination';
import AppTitle from '@/components/AppTitulo';
import AuctionTable from '@/views/Reports/AuctionTable';
import AuctionFilters from '@/views/Pregao/PregaoLista/Filtros';
import ReportOptions from '@/views/Reports/ReportOptions';

export default {
  name: 'AuctionListing',
  components: {
    AppPagination,
    AppTitle,
    AuctionTable,
    AuctionFilters,
    ReportOptions,
  },
  data() {
    const columns = [
      { name: 'Comprador', display: false },
      { name: 'Processo', display: false },
      { name: 'Número', display: true },
      { name: 'Tipo', display: false },
      { name: 'Disputa', display: false },
      { name: 'Negociação', display: false },
      { name: 'Edital', display: false },
      { name: 'Preferência Regional', display: false },
      { name: 'Objeto', display: true },
      { name: 'Publicação', display: false },
      { name: 'Abertura', display: true },
      { name: 'Reabertura', display: false },
      { name: 'Origem dos Recursos', display: false },
      { name: 'Nº Instrumento' },
      { name: 'Ano Instrumento', display: false },
      { name: 'Situação', display: true },
      { name: 'Itens', display: false },
    ];
    const filters = {
      page: 1,
      perPage: 20,
    };

    return {
      columns,
      filters,
      displayExpandedDescription: false,
      expandedDescription: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.limparFiltrosPregoes();
    return next();
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        const itemActive = this.columns.some(column => column.name === 'Itens' && column.display);
        if (itemActive) {
          this.verifyExpandedDescription();
        }
      },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      auctions: 'obterOfertasCompra',
      count: 'obterTotalOfertasCompra',
      loading: 'obterCarregandoOfertasCompra',
    }),
  },
  methods: {
    ...mapActions(['alterarPorPaginaPregoes', 'filtrarPregoes', 'limparFiltrosPregoes']),
    checkSeller() {
      if (this.company.seller) {
        this.columns[0].display = true;
      }
    },
    printOut() {
      const impression = new Printd();
      const table = this.$refs['auction-table'].$el.children[1].children[0];
      impression.print(table, [
        'a { color: black; text-decoration: none; }',
        'table { width: 100%; }',
        'td { text-align: center; }',
        'table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 14px; }',
        '.auction-column { margin: 0 2px; }',
        this.expandedDescription
          ? ''
          : '.elipsis-description { max-width: 50ch; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}',
        '.item-container { display: flex; flex-wrap: wrap; margin-bottom: 1rem }',
        '.item-content { margin: 0.5rem 0.5rem; min-width: 300px; text-align: justify; }',
      ]);
    },
    verifyExpandedDescription() {
      setTimeout(() => {
        const table = this.$refs['auction-table'];
        if (table) {
          const itemBox = table.$refs.description;
          const itemContainer = table.$refs['item-container'];

          this.displayExpandedDescription = itemBox.some(
            (box, index) => box.scrollWidth >= itemContainer[index].scrollWidth,
          );
        }
      }, 1);
    },
  },
  async created() {
    await this.filtrarPregoes();
  },
  mounted() {
    this.checkSeller();
  },
};
</script>

<style lang="scss" scoped>
.auction-filters-container {
  margin-top: 3rem;
}

.auction-options-container {
  margin: 1rem 0;
}

.auction-table-container {
  margin-bottom: 2rem;
}

.empty-panel {
  justify-content: center !important;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
</style>
